<template>
    <div>
        <selectEntity :type="businessInfo.entity_type" isCtf disabled></selectEntity>
        <card title="主体信息">
            <bisLicense class="" ref="bisLicense" @selectImg="selectImg" :info="businessInfo" :type="type" :certStatus="certStatus"></bisLicense>
        </card>
        <card title="经营者/法人认证信息">
            <idCardLis class="" ref="idCardLis" @selectImg="selectImg" :info="businessInfo" :type="type"></idCardLis>
        </card>
        <card title="经营信息">
            <personInfo class="ml-30" ref="personInfo" :info="businessInfo" :isBType="false" :type="type"></personInfo>
        </card>
        <card title="验证管理员身份">
            <bankCardsInfo class="ml-30" ref="bankCardsInfo" :isOnlyPhoneCode="true" :type="type"></bankCardsInfo>
        </card>
        <div class="flex-center justify-center pb-20">
            <el-button type="primary" @click="next">
                提交
            </el-button>
        </div>
        <change-photoimg :size="2" @complete="completephoto" class="changePhotoimg" ref="changePhotoimg"
            v-show="false">
        </change-photoimg>
    </div>
</template>

<script>
import changePhotoimg from '@cm/base/changePhotoimg'
import selectEntity from './selectEntity.vue'
import card from './card.vue'
import bisLicense from './bisLicense.vue'
import idCardLis from './idCardLis.vue'
import personInfo from './personInfo'
import bankCardsInfo from './bankCardsInfo'
export default {
    name: 'businessCtf',
    props:{
        title:String,
        hidHead:Boolean,
        info:Object,
        type:String,
        certStatus:Number,
    },
    data: () => ({
        // 1:bis 2:idcard
        downImg:0,
        businessInfo:{},
    }),
    components: {
        changePhotoimg,
        selectEntity,
        card,
        bisLicense,
        idCardLis,
        personInfo,
        bankCardsInfo,
    },
    watch:{
        info:{
            handler(newObj){
                this.businessInfo = Object.assign(newObj,{
                    entity_type:Number(newObj.entity_type),
                })
            },
            immediate:true,
        }
    },
    methods:{
        selectImg(type) {
            this.downImg = type
            this.$refs.changePhotoimg.changePicture()
        },
        // 添加图片
        completephoto(val) {
            switch (Number(this.downImg)) {
                case 1:
                    this.$refs.bisLicense.completephoto(val)
                    break;
                case 2:
                    this.$refs.idCardLis.completephoto(val)
                    break;
            }
        },
        back(){
            this.$emit('back')
        },
        async next(){
            const bisLicenseInfo = await this.$refs.bisLicense.submit()
            const idCardLisInfo = await this.$refs.idCardLis.submit()
            const personInfoInfo = await this.$refs.personInfo.submit()
            const bankCardsInfoInfo = await this.$refs.bankCardsInfo.submit()
            const obj = {
                entity_type:this.businessInfo.entity_type,
                // 营业执照
                business_license:bisLicenseInfo.imgs,
                merchant_name:bisLicenseInfo.merchant_name,
                credit_code:bisLicenseInfo.credit_code,
                business_term:bisLicenseInfo.business_term,
                business_start_time:bisLicenseInfo.date[0]/1000,
                business_end_time:bisLicenseInfo.date[1]/1000,
                registered_address:bisLicenseInfo.registered_address,
                business_scope:bisLicenseInfo.business_scope,
                operator_name:bisLicenseInfo.operator_name,
                // 经营者/法人认证信息
                id_type:idCardLisInfo.id_type,
                id_photo_back:idCardLisInfo.imgs[0],
                id_photo_front:idCardLisInfo.imgs[1],
                id_name:idCardLisInfo.id_name,
                id_number:idCardLisInfo.id_number,
                id_long_term:idCardLisInfo.id_long_term,
                id_start_time:idCardLisInfo.date[0]/1000,
                id_end_time:idCardLisInfo.date[1]/1000,
                id_address:idCardLisInfo.id_address,
                // 经营信息
                contacts_name:personInfoInfo.contacts_name,
                contacts_phone:personInfoInfo.contacts_phone,
                contacts_address:personInfoInfo.area.concat(personInfoInfo.contacts_address),
                // contacts_type:personInfoInfo.contacts_type,
                // 管理员验证码
                code:bankCardsInfoInfo.code,
            }
            console.log("info",obj);
            this.$emit('next',obj)
        }
    }
}
</script>

<style scoped lang="scss">

.changeCtf {
    margin-left: 190px;
    opacity: 0.7;
    a {
        color: blue;
        // cursor: pointer;
    }
}
</style>