<template>
    <div>
        <selectEntity :type="personInfo.entity_type" isCtf disabled></selectEntity>
        <card title="个人信息">
            <credentials class="ml-30" ref="credentials" :info="personInfo" :type="type" :certStatus="certStatus"></credentials>
        </card>
        <card title="经营信息">
            <personInfo class="ml-30" ref="personInfo" :info="personInfo" :isBType="true" :type="type"></personInfo>
        </card>
        <card title="验证管理员身份">
            <bankCardsInfo class="ml-30" ref="bankCardsInfo" :isOnlyPhoneCode="true" :type="type"></bankCardsInfo>
        </card>
        <div class="flex-center justify-center pb-20">
            <el-button type="primary" @click="next">
                提交
            </el-button>
        </div>
    </div>
</template>

<script>
import selectEntity from './selectEntity.vue'
import card from './card.vue'
import credentials from './credentials'
import personInfo from './personInfo'
import bankCardsInfo from './bankCardsInfo'
export default {
    name: 'personCtf',
    props:{
        title:String,
        hidHead:Boolean,
        info:Object,
        type:String,
        certStatus:Number,
    },
    data: () => ({
        personInfo:{},
    }),
    components: {
        selectEntity,
        card,
        credentials,
        personInfo,
        bankCardsInfo,
    },
    watch:{
        info:{
            handler(newObj){
                this.personInfo = Object.assign(newObj,{
                    entity_type:Number(newObj.entity_type),
                })
            },
            immediate:true,
        }
    },
    created() {
    },
    methods:{
        back(){
            this.$emit('back')
        },
        async next(){
            const credentialsInfo = await this.$refs.credentials.submit()
            const personInfoInfo = await this.$refs.personInfo.submit()
            const bankCardsInfoInfo = await this.$refs.bankCardsInfo.submit()
            const obj = {
                entity_type:this.personInfo.entity_type,
                id_type:credentialsInfo.id_type,
                // 证件照片反面(人像面)
                id_photo_back:credentialsInfo.imgs[0],
                // 证件照片正面(国徽面)
                id_photo_front:credentialsInfo.imgs[1],
                id_name:credentialsInfo.id_name,
                id_number:credentialsInfo.id_number,
                id_long_term:credentialsInfo.id_long_term,
                id_start_time:credentialsInfo.date[0]/1000,
                id_end_time:credentialsInfo.date[1]/1000,
                id_address:credentialsInfo.id_address,
                // 经营信息
                contacts_name:personInfoInfo.contacts_name,
                contacts_phone:personInfoInfo.contacts_phone,
                contacts_address:personInfoInfo.area.concat(personInfoInfo.contacts_address),
                contacts_type:personInfoInfo.contacts_type,
                // 管理员验证码
                code:bankCardsInfoInfo.code,
            }
            console.log("Info",obj);
            this.$emit('next',obj)
        }
    }
}
</script>

<style scoped lang="scss">
@import '../css/upload.scss';
</style>