<template>
    <div class="container">
        <card title="" :hidHead="true">
            <p class="Header">您可以对当前有效店铺认证的经营信息或经营资质 进行补充或者修改</p>
        </card>
        <card class="info-border" title="" :hidHead="true">
            <div class="info-text flex-center justify-center text-center dr-c">
                <p class="font-14 fw-700">经营信息补充/修改</p>
                <p class="font-14 ">仅补充/修改经营信息（经营地址，经营类目资质），但不可修改店铺经营主体信息中的主体类型/个人主体的身份证号/非个人主体的统一社会信用代码</p>
            </div>
            <i class="el-icon-check right-top-icon"></i>
        </card>
        <div class="next flex-center justify-center">
            <el-button type="primary" @click="back()">
                取消
            </el-button>
            <el-button type="primary" @click="next">
                开始认证
            </el-button>
        </div>
    </div>
</template>

<script>
import card from './card.vue'
export default {
    name: 'editCtf',
    props:{},
    data(){
        return {
        }
    },
    components:{
        card,
    },
    methods:{
        back(){
            this.$router.back()
        },
        next(){
            this.$emit('next')
        },
    }
}
</script>

<style scoped lang="scss">
@import '../css/upload.scss';
.container {
//   height: 600px;
  padding:0 120px;
  flex-direction: column;
    .Header {
        font-size: 20px;
        font-weight: bold;
        color: rgba(51, 51, 51, 1);
        text-align: center;
        margin-bottom: 50px;
    }
    .info-border {
        border: 2px solid rgb(144,179,253);
        position: relative; /* 为包含三角形伪元素做准备 */
        overflow: hidden; /* 防止三角形超出div影响布局 */
        border-radius: 5px;
        margin-bottom: 250px;
    }
    /* 创建右上角的蓝色三角形 */
    .info-border::before {
        content: ""; /* 伪元素必须有内容 */
        position: absolute; /* 绝对定位，使其能够相对于.base-div定位 */
        top: -10px; /* 向上偏移，调整以适应三角形大小 */
        right: -10px; /* 向右偏移，调整以适应三角形大小 */
        width: 0;
        height: 0;
        border-style: solid; /* 使用边框绘制三角形 */
        border-width: 0 10px 10px 0; /* 调整边框宽度以形成三角形 */
        border-color: transparent transparent rgb(16,92,251); /* 只需改变右边和底部的颜色 */
        border-top: 25px solid rgb(16,92,251);
        border-right: 25px solid rgb(16,92,251);
        border-bottom: 25px solid rgb(255, 255, 255);
        border-left: 25px solid rgb(255, 255, 255);
    }
    .info-text {
        line-height: 28px;
    }
    .right-top-icon {
        position: absolute;
        right: 3px;
        top: 1px;
        color: #fff;
        font-size: 14px;
    }
    .next {
        padding-bottom: 20px;
    }
}
</style>