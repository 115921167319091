<template>
    <el-image
      :src="imageUrl"
      @load="handleImageLoad"
      :preview-src-list="preview"
      :style="{ height: imageHeight }"
      fit="contain"
    >
      <div slot="error" class="image-slot">
        图片加载失败
      </div>
    </el-image>
  </template>
  
  <script>
  export default {
    props:{
        imageUrl:String,
        preview:Array,
    },
    data() {
      return {
        imageWidth: 0, // 图片宽度
        imageHeight: '', // 图片高度，初始为空，将在load事件处理函数中计算并设置
      };
    },
    methods: {
      handleImageLoad(event) {
        const img = event.target;
        this.imageWidth = img.naturalWidth; // 图片实际宽度
        this.imageHeight = this.imageWidth > img.naturalHeight ? 'auto' : '240px';
      },
    },
  };
  </script>