<template>
    <div class="container">
        <el-form ref="form" label-position="left" :rules="rules" :model="form">
            <el-form-item label="证件类型" prop="id_type" label-width="150px" class="ml-30">
                <el-select class="selectBtn" v-model="form.id_type" placeholder="请选择">
                    <el-option
                        v-for="item in IdCardOption"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="经营者身份证照片" prop="imgs" label-width="150px" class="ml-30">
                <div class="creImgBox">
                    <div class="columnBox imgUpload">
                        <div class="columnBox imgCenter" @click="selectImg(0)">
                            <div v-if="!form.imgs[0]" class="item columnBox back" :key="key1">
                                <i class="photo"></i>
                                <p>上传证件照片（人像面）</p>
                            </div>
                            <div v-else>
                                <el-image class="cp" :src="form.imgs[0]" fit="fill">
                                </el-image>
                            </div>
                        </div>
                        <div class="columnBox imgCenter" @click="selectImg(1)" :key="key1 + 1">
                            <div v-if="!form.imgs[1]" class="item columnBox front">
                                <i class="photo"></i>
                                <p>上传证件照片（国徽面）</p>
                            </div>
                            <div v-else>
                                <el-image class="cp" :src="form.imgs[1]" fit="fill">
                                </el-image>
                            </div>
                        </div>
                    </div>
                    <div class="columnBox imgtext">
                        <p class="content">证件照片需为服片或者彩色扫描件；</p>
                        <p class="content">格式要求：jpg、jpeg、png格式照片，大小不超过2M</p>
                    </div>
                </div>
            </el-form-item>
            <el-form-item label="" prop="" label-width="">
                <div class="mt-20">
                    <cardLicense ref="cardLicense" title="以下信息须与证件照片信息一致" :isIdCard="true" :info="businessInfo" :type="type">
                    </cardLicense>
                </div>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import {
    IdCardOption,
} from './mock.js'
import cardLicense from './cardLicense'
export default {
    name: 'idCardLis',
    props:{
        title:String,
        hidHead:Boolean,
        info:Object,
        type:String,
    },
    computed:{
        isEditCtf(){
            return this.type=='edit-business'
        }
    },
    watch:{
        info:{
            handler(newObj){
                this.businessInfo = Object.assign(newObj,{
                    entity_type:Number(newObj.entity_type)
                })
                this.form.id_type = newObj.id_type
                this.form.imgs = [newObj.id_photo_back,newObj.id_photo_front]
            },
            immediate:true,
        }
    },
    data(){
        const validateImgs = (rule, value, callback) => {
            const [img1, img2] = this.form.imgs
            if (!img1 || !img2) {
                callback(new Error('请上传证件照片'));
            } else {
                callback();
            }
        }
        return {
            businessInfo:{},
            imgType: 0,
            form: {
                id_type:'',
                imgs: [],
            },
            rules: {
                id_type: [
                    { required: true, message: '请输入证件类型', trigger: 'change' },
                ],
                imgs: [
                    {
                        type: 'array',
                        required: true,
                        message: '请上传图片',
                        trigger: 'blur'
                    },
                    { validator: validateImgs, trigger: 'blur' }
                ],
            },
            IdCardOption: IdCardOption,
            key1: 0,
        }
    },
    components: {
        cardLicense,
    },
    methods:{
        // 添加图片
        completephoto(val) {
            this.key1 += 1
            switch (this.imgType) {
                case 0:
                    this.form.imgs[0] = val
                    break;
                case 1:
                    this.form.imgs[1] = val
                    break;
            }
        },
        selectImg(type) {
            if(this.isEditCtf) return
            this.imgType = type
            this.$emit("selectImg",2)
        },
        async submit() {
            return new Promise((res, rej) => {
                this.$refs.form.validate(async (valid) => {
                    const cardLicenseInfo = await this.$refs.cardLicense.submit()
                    if (valid&&cardLicenseInfo) {
                        console.log(Object.assign(this.form,cardLicenseInfo));
                        res(Object.assign(this.form,cardLicenseInfo))
                    } else {
                        rej(false)
                    }
                })

            })
        },
    }
}
</script>

<style scoped lang="scss">
@import '../css/upload.scss';
.previewImages {
  width:560px;
}
</style>