<template>
    <div class="container flex-center justify-center">
        <div class="review flex-center justify-center pb-60">
            <i class="el-icon-warning-outline icon"></i>
            <span class="font-14">审核中</span>
        </div>
        <p class="content font-14">资料提交成功，请等待管理员审核！预计7个工作日内审核完毕</p>
    </div>
</template>

<script>
export default {
    name: 'review',
    props:{
    },
    data(){
        return {
        }
    },
    components: {
    },
    methods:{

    }
}
</script>

<style scoped lang="scss">
@import '../css/upload.scss';
.container {
  height: 600px;;
  width: 100%;
  flex-direction: column;
  .review {
    flex-direction: column;
    .icon {
        font-size: 80px;
        color: #0aa29b;
    }
  }
  .content {}
}
</style>