<template>
    <div>
        <card title="" :hidHead="true">
            <p class="Header">叮叮开课将根据《中华人民共和国电子商务法》要求，收集入驻商家信息，请选择您的店铺主体类型并提交相应认证材料</p>
        </card>
        <selectEntity @setType="setType" isText :type="entity_type"></selectEntity>
        <card title="所需材料">
            <p class="font-14 ml-20">1，证明店铺归属人的材料：个人店铺，个人证件照；非个人店铺：法定代表人身份证正反面照片；</p>
            <p class="font-14 mt-6 ml-20">2，提供真实合法的经营信息：营业执照；</p>
            <p class="font-14 mt-6 ml-20">3，照片规则：照片需四角完整，清晰可辨，若加水印需保证照片重要信息清晰可辨；可提交复印件，每张复印件均需加盖完整红色公章；</p>
        </card>
        <card title="特别说明">
            <p class="font-14 ml-20">认证审核通过后不能修改主体类型</p>
        </card>
        <div class="next flex-center justify-center">
            <el-button type="primary" @click="next">
                开始认证
            </el-button>
        </div>
    </div>
</template>

<script>
import selectEntity from './selectEntity.vue'
import card from './card.vue'
export default {
    name: 'startToCtf',
    data: () => ({
        entity_type: 1,
    }),
    components:{
        card,
        selectEntity,
    },
    methods:{
        setType(value){
            this.entity_type=value
        },
        next(){
            this.$emit('next',{
                type:this.entity_type==1?'person':'business',
                info:{
                    entity_type:this.entity_type,
                }
            })
        },
    }
}
</script>

<style scoped lang="scss">
.Header {
    font-size: 20px;
    font-weight: bold;
    color: rgba(51, 51, 51, 1);
    text-align: center;
}
.next {
    padding-bottom: 20px;
}
</style>