<template>
  <div class="container">
    <el-form ref="form" label-position="left" :rules="rules" :model="form">
      <el-form-item :label="text" prop="imgs" label-width="170px" class="ml-30">
        <div class="creImgBox">
          <div class="columnBox imgUpload">
            <div class="columnBox imgCenter" @click="selectImg()">
              <div v-if="!form.imgs" class="item columnBox license" :key="key1">
                <i class="el-icon-plus"></i>
                <p>{{ '上传'+text }}</p>
              </div>
              <div v-else>
                <el-image class="cp" :src="form.imgs" fit="fill">
                </el-image>
              </div>
            </div>
          </div>
          <div class="columnBox imgtext">
            <p class="content">{{ text2 }}</p>
            <el-popover placement="bottom" trigger="hover" width="auto">
              <div class="previewImages">
                <img src="@/assets/img/1.4.50/License.png" style="width: 100%;" v-if="info.entity_type==2||info.entity_type==3"/>
                <img src="@/assets/img/1.4.50/tyshhxydmzs.png" style="width: 100%;" v-if="info.entity_type==4"/>
                <img src="@/assets/img/1.4.50/mbfqydwdjzs.png" style="width: 100%;" v-if="info.entity_type==5"/>
              </div>
              <span slot="reference" style="color: #1DA9A2;font-size: 12px;cursor: pointer;">查看示例</span>
            </el-popover>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="" prop="" label-width="">
        <div class="mt-20">
          <cardLicense ref="cardLicense" title="以下信息须与证件照片信息一致" :isLicense="true" :info="businessInfo" :type="type"
            :certStatus="certStatus">
          </cardLicense>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import cardLicense from './cardLicense'

export default {
  name: 'bisLicense',
  props: {
    title: String,
    hidHead: Boolean,
    info: Object,
    type: String,
    certStatus: Number,
  },
  computed: {
    isEditCtf() {
      return this.type == 'edit-business'
    },
    text() {
      let str = ''
      switch (this.info.entity_type) {
        case 2:
        case 3:
          str = '营业执照'
          break;
        case 4:
          str = '统一社会信用代码证书'
          break;
        case 5:
          str = '登记证书'
          break;
      }
      return str
    },
    text2() {
      let str = ''
      switch (this.info.entity_type) {
        case 2:
        case 3:
          str = '请上传营业执照的照片或者彩色扫描件，盖章和证件号都清晰可见，支持扩展名：jpg、jpeg、png格式照片，大小不超过2M'
          break;
        case 4:
          str = '仅支持在有效期内的统一社会信用代码证。格式要求:原件照片、扫描件或者复印件加盖企业公章后的扫描件，支持jpg、jpeg、png格式照片，大小不超过2M。'
          break;
        case 5:
          str = '仅支持在有效期内的登记证书。格式要求:原件照片、扫描件或者复印件加盖企业公章后的扫描件，支持jpg、jpeg、png格式照片，大小不超过2M。'
          break;
      }
      return str
    },
  },
  watch: {
    info: {
      handler(newObj) {
        this.businessInfo = Object.assign(newObj, {
          entity_type: Number(newObj.entity_type)
        })
        this.form.imgs = newObj.business_license
      },
      immediate: true,
    }
  },
  data() {
    const validateImgs = (rule, value, callback) => {
      const imgs = this.form.imgs
      if (!imgs) {
        callback(new Error('请上传证件照片'));
      } else {
        callback();
      }
    }
    return {
      businessInfo: {},
      form: {
        imgs: '',
      },
      rules: {
        imgs: [
          {
            required: true,
            message: '请上传图片',
            trigger: 'blur'
          },
          { validator: validateImgs, trigger: 'blur' }
        ],
      },
      key1: 0,
    }
  },
  components: {
    cardLicense,
  },
  methods: {
    // 添加图片
    completephoto(val) {
      console.log("添加图片", val);
      this.form.imgs = val
      this.key1 += 1
    },
    selectImg() {
      if (this.isEditCtf) return
      this.$emit("selectImg", 1)
    },
    async submit() {
      return new Promise((res, rej) => {
        this.$refs.form.validate(async (valid) => {
          const cardLicenseInfo = await this.$refs.cardLicense.submit()
          if (valid && cardLicenseInfo) {
            console.log(Object.assign(this.form, cardLicenseInfo));
            res(Object.assign(this.form, cardLicenseInfo))
          } else {
            rej(false)
          }
        })
      })
    },
  },
}
</script>

<style scoped lang="scss">
@import '../css/upload.scss';

.previewImages {
  width: 320px;
}
</style>