<template>
  <div class="container">
    <card title="主体类型">
      <p class="check-content">
        <span class="ml-60 mr-20">认证类型：</span>
        <span v-if="checkInfo.entity_type == 1">个人</span>
        <span v-if="checkInfo.entity_type == 2">个体工商户</span>
        <span v-if="checkInfo.entity_type == 3">企业</span>
        <span v-if="checkInfo.entity_type == 4">党政、机关及事业单位</span>
        <span v-if="checkInfo.entity_type == 5">其他组织</span>
      </p>
    </card>
    <card title="个人信息" v-if="type == 'check-person'">
      <p class="check-content">
        <span class="ml-60 mr-20">姓名：</span>
        <span>{{ maskString(checkInfo.id_name, 1) }}</span>
      </p>
      <p class="check-content">
        <span class="ml-60 mr-20">证件类型：</span>
        <span>{{ checkInfo.id_type }}</span>
      </p>
      <p class="check-content">
        <span class="ml-60 mr-20">证件号码：</span>
        <span>{{ maskString(checkInfo.id_number, 6, 4) }}</span>
      </p>
      <p class="check-content">
        <span class="ml-60 mr-20">证件照片反面(人像面)：</span>
        <checkImg class="liscense cp" :imageUrl="checkInfo.id_photo_back" :preview="[checkInfo.id_photo_back]">
        </checkImg>
      </p>
      <p class="check-content">
        <span class="ml-60 mr-20">证件照片正面(国徽面)：</span>
        <checkImg class="liscense cp" :imageUrl="checkInfo.id_photo_front" :preview="[checkInfo.id_photo_front]">
        </checkImg>
      </p>
    </card>
    <card title="主体信息" v-if="type == 'check-business'">
      <p class="check-content">
      </p>
      <p class="check-content">
        <span class="ml-60 mr-20">{{text}}：</span>
        <span>{{ checkInfo.merchant_name }}</span>
      </p>
      <p class="check-content">
        <span class="ml-60 mr-20">统一社会信用代码：</span>
        <span>{{ checkInfo.credit_code }}</span>
      </p>
      <p class="check-content">
        <span class="ml-60 mr-20">营业期限：</span>
        <span v-if="checkInfo.business_term == 1">{{ checkInfo.business_start_time | formatTimeStamp('yyyy-MM-dd') }} - {{
          checkInfo.business_end_time | formatTimeStamp('yyyy-MM-dd') }}</span>
        <span v-if="checkInfo.business_term == 2">长期有效</span>
      </p>
      <p class="check-content">
        <span class="ml-60 mr-20">注册地址：</span>
        <span>{{ checkInfo.registered_address }}</span>
      </p>
      <p class="check-content">
        <span class="ml-60 mr-20">经营范围：</span>
        <span>{{ checkInfo.business_scope }}</span>
      </p>
      <p class="check-content">
        <span class="ml-60 mr-20">经营者姓名：</span>
        <span>{{ checkInfo.operator_name }}</span>
      </p>
      <p class="check-content">
        <span class="ml-60 mr-20">{{text2}}:</span>
        <checkImg class="liscense cp" :imageUrl="checkInfo.business_license" :preview="[checkInfo.business_license]">
        </checkImg>
      </p>
    </card>
    <card title="经营者/法人认证信息" v-if="type == 'check-business'">
      <p class="check-content">
        <span class="ml-60 mr-20">证件持有人姓名：</span>
        <span>{{ maskString(checkInfo.id_name, 1) }}</span>
      </p>
      <p class="check-content">
        <span class="ml-60 mr-20">证件号码：</span>
        <span>{{ maskString(checkInfo.id_number, 6, 4) }}</span>
      </p>
      <p class="check-content">
        <span class="ml-60 mr-20">证件有效期</span>
        <span v-if="checkInfo.id_long_term == 1">{{ checkInfo.id_start_time | formatTimeStamp('yyyy-MM-dd') }} - {{
          checkInfo.id_end_time | formatTimeStamp('yyyy-MM-dd') }}</span>
        <span v-if="checkInfo.id_long_term == 2">长期有效</span>
      </p>
      <p class="check-content">
        <span class="ml-60 mr-20">证件地址：</span>
        <span>{{ checkInfo.id_address }}</span>
      </p>
      <p class="check-content">
        <span class="ml-60 mr-20">证件照片反面(人像面)：</span>
        <checkImg class="liscense cp" :imageUrl="checkInfo.id_photo_back" :preview="[checkInfo.id_photo_back]">
        </checkImg>
      </p>
      <p class="check-content">
        <span class="ml-60 mr-20">证件照片正面(国徽面)：</span>
        <checkImg class="liscense cp" :imageUrl="checkInfo.id_photo_front" :preview="[checkInfo.id_photo_front]">
        </checkImg>
      </p>
    </card>
    <card title="经营信息">
      <p class="check-content">
        <span class="ml-60 mr-20">联系人姓名：</span>
        <span>{{ maskString(checkInfo.contacts_name, 1) }}</span>
      </p>
      <p class="check-content">
        <span class="ml-60 mr-20">联系方式：</span>
        <span>{{ maskString(checkInfo.contacts_phone, 3, 4) }}</span>
      </p>
      <p class="check-content">
        <span class="ml-60 mr-20">经营地址：</span>
        <span>{{ checkInfo.contacts_address }}</span>
      </p>
      <p class="check-content" v-if="type == 'check-person'">
        <span class="ml-60 mr-20">已审核经营类目：</span>
        <span>{{ checkInfo.contacts_type }}</span>
      </p>
    </card>
  </div>
</template>

<script>
import card from './card.vue'
import checkImg from './checkImg.vue'
import {
  maskString,
} from '../../../utils/index'

export default {
  name: 'checkCtf',
  props: {
    info: Object,
    type: String,
  },
  computed: {
    text() {
      let str = ''
      switch (this.checkInfo.entity_type) {
        case 2:
          str = '商户名称'
          break;
        case 3:
          str = '企业名称'
          break;
        case 4:
          str = '机构名称'
          break;
        case 5:
          str = '组织名称'
          break;
      }
      return str
    },
    text2() {
      let str = ''
      switch (this.checkInfo.entity_type) {
        case 2:
        case 3:
          str = '营业执照'
          break;
        case 4:
          str = '统一社会信用代码证'
          break;
        case 5:
          str = '登记证书'
          break;
      }
      return str
    },
  },
  watch: {
    info: {
      handler(newObj) {
        this.checkInfo = Object.assign(newObj, {
          entity_type: Number(newObj.entity_type)
        })
      },
      immediate: true,
    }
  },
  data() {
    return {
      checkInfo: {
        contacts_name:'',
        contacts_phone:'',
      },
    }
  },
  components: {
    card,
    checkImg,
  },
  methods: {
    maskString,
    next() {
    },
  }
}
</script>

<style scoped lang="scss">
@import '../css/upload.scss';

.container {
  //   height: 600px;
  //   padding:0 60px;
  flex-direction: column;

  .check-content {
    font-size: 16px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;

    .liscense {
      // height: 240px;
      width: 240px;
    }
  }
}
</style>