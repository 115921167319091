<template>
  <card title="主体类型" :helpIcon="true" :content="content" :isWhiteSpace="true">
    <span class="font-14 ml-60 mr-20">选择主体类型：</span>
    <el-radio-group v-model="entity_type" :disabled="disabled">
        <el-radio :label="1">个人</el-radio>
        <el-radio :label="2">个体工商户</el-radio>
        <el-radio :label="3">企业</el-radio>
        <el-radio :label="4">党政、机关及事业单位</el-radio>
        <el-radio :label="5">其他组织</el-radio>
    </el-radio-group>
    <p class="font-14 mt-16 changeCtf" v-if="isCtf">如需更改主体类型，请在<a>认证起始页</a>进行操作</p>
    <p class="font-14 ml-60 mt-10 color-A1" v-if="isText" v-html="text"></p>
  </card>
</template>

<script>
import card from './card.vue'
export default {
  name:'selectEntity',
  props:{
    disabled:{
      type:Boolean,
      default:false,
    },
    isCtf:Boolean,
    isText:Boolean,
    type:Number,
  },
  components:{
      card,
  },
  computed:{
    text(){
      let str = ''
      switch (this.entity_type) {
        case 1:
          str = `[个人]&nbsp为根据法律法规和相关规定免于办理工商登记，无营业执照的商家`
          break;
        case 2:
          str = '[个体工商户]&nbsp在营业执照上一般为：个体户、个体工商户、个体经营'
          break;
        case 3:
          str = '[企业]&nbsp在营业执照上一般为：有限公司、有限责任公司等'
          break;
        case 4:
          str = '[党政、机关及事业单位]&nbsp在营业执照上一般为：国内各类政府、事业单位，如公安、市政、党团等'
          break;
        case 5:
          str = '[其他组织]&nbsp一般为：社会团体、民办非企业、农民专业合作社、基金会等'
          break;
      }
      return str
    },
  },
  data: () => ({
      entity_type: 1,
      content:`可参考营业执照中的“类型”：\n[其他组织]一般为：社会团体、民办非企业、农民专业合作社、基金会等\n[党政、机关及事业单位]在营业执照上一般为：国内各类政府、事业单位，如公安、市政、党团等\n[企业]在营业执照上一般为：有限公司、有限责任公司等；\n[个体工商户]在营业执照上一般为：个体户、个体工商户、个体经营；\n[个人]为根据法律法规和相关规定免于办理工商登记，无营业执照的商家。`,
  }),
  watch:{
    type:{
      handler(newVal){
        if(newVal === this.entity_type) return
        this.entity_type=newVal
      },
      immediate:true,
    },
    entity_type(newVal){
      this.$emit('setType',newVal)
    }
  },
}
</script>

<style scoped lang="scss">
.changeCtf {
    margin-left: 190px;
    opacity: 0.7;
    a {
        color: blue;
    }
}
.color-A1{
  color: #A1A1A1;
}
</style>