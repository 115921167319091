<template>
    <div>
        <startToCtf v-if="type=='start'" @next="startCtf"></startToCtf>
        <personCtf v-if="type=='person'||type=='edit-person'" @back="back" @next="setActivation" :info="info" :type="type" :certStatus="certStatus"></personCtf>
        <businessCtf v-if="type=='business'||type=='edit-business'" @back="back" @next="setActivation" :info="info" :type="type" :certStatus="certStatus"></businessCtf>
        <review v-if="type=='review'"></review>
        <reviewFail v-if="type=='reviewFail'" :error="error" @next="reToCtf"></reviewFail>
        <editCtf v-if="type=='editCtf'" :info="info" :type="type" @next="startToEditCtf"></editCtf>
        <checkCtf v-if="type=='check-person'||type=='check-business'" :info="info" :type="type"></checkCtf>
    </div>
</template>

<script>
import API from '@/request/api'
import startToCtf from './components/startToCtf.vue'
import personCtf from './components/personCtf.vue'
import businessCtf from './components/businessCtf.vue'
import review from './components/review.vue'
import reviewFail from './components/review-fail.vue'
import editCtf from './components/editCtf.vue'
import checkCtf from './components/checkCtf.vue'
export default {
    name: 'storeCertification',
    data: () => ({
        // 流程类型  流程结束后查看check-person check-business
        type:'start',
        certStatus:0,
        // 编辑的时候判断
        entityType:0,
        info:{
            entity_type:1,
        },
        error:'',
    }),
    components:{
        startToCtf,
        personCtf,
        businessCtf,
        review,
        reviewFail,
        editCtf,
        checkCtf,
    },
    created() {
        this.certStatus = Number(this.$route.query.type)
        this.entityType = Number(this.$route.query.entity_type)
        this.type = this.$route.query.certType
        if(this.type == 'editCtf') return
        this.getStatus()
    },
    methods:{
        startCtf(item){
            this.type = item.type 
            this.info = item.info
        },
        back(){
            this.type = 'start'
        },
        reToCtf(){
            if(this.entityType == 1) this.type = 'edit-person'
            if(this.entityType != 1) this.type = 'edit-business'
            this.getCertInfo()
        },
        getStatus(){
            // 获取审核状态
            this.$http({
                name: API.certGetStatus_Api.name,
                url: API.certGetStatus_Api.url,
                data: {},
                callback:(res) => {
                    if(res.data){
                        const { status,entity_type,error } = res.data
                        // const status = 2
                        // const entity_type = 4
                        // const error = ''

                        this.certStatus = status
                        this.entityType = entity_type
                        switch (Number(status)) {
                            // 审核中 查看审核
                            case 1:
                                this.type = 'review'
                                break;
                            case 2:
                                if(entity_type == 1) this.type = 'check-person'
                                if(entity_type != 1) this.type = 'check-business'
                                break;
                            case 3:
                                this.type = 'reviewFail';this.error=error;
                                break;
                        }
                        if(Number(status)==2) this.getCertInfo()
                    }
                },
            })
        },
        getCertInfo(){
            // 获取认证数据
            const that = this
            this.$http({
                name: API.certGetActivation_Api.name,
                url: API.certGetActivation_Api.url,
                data: {},
                callback:(res) => {
                    that.info = res.data
                },
            })
        },
        setActivation(obj){
            // 店铺认证/修改认证
            this.$http({
                name: API.certActivation_Api.name,
                url: API.certActivation_Api.url,
                data: obj,
                callback:() => {
                    this.getStatus()
                },
            })
        },
        startToEditCtf(){
            if(this.entityType==1)this.type = 'edit-person'
            if(this.entityType!=1)this.type = 'edit-business'
            this.getCertInfo()
        },
    }
}
</script>

<style scoped lang="scss"></style>