<template>
    <div class="container flex-center justify-center">
        <div class="review flex-center  justify-center pb-20">
            <img src="~@ass/img/1.4.50/review-fail.svg" class="icon" alt="" />
            <span class="content font-16">{{ error }}</span>
        </div>
        <el-button type="primary" @click="next">
            重新提交
        </el-button>
    </div>
</template>

<script>
export default {
    name: 'review-fail',
    props:{
        error:String,
    },
    data(){
        return {
        }
    },
    components: {
    },
    methods:{
        next(){
            this.$emit('next')
        }
    }
}
</script>

<style scoped lang="scss">
@import '../css/upload.scss';
.container {
  min-height: 400px;;
  width: 100%;
  flex-direction: column;
  padding: 100px 0 50px 0;
  .review {
    flex-direction: column;
    .icon {
        font-size: 100px;
        width: 80px;
        padding-bottom: 20px;
    }
    .content {
        word-break: break-all;
        width: 70%;
        padding-bottom: 130px;
        line-height: 30px;
    }
  }
}
</style>