<template>
    <div>
        <el-card class="box-card" :class="`${hidHead?'hidHead':''}`">
            <div slot="header" class="title">
                <span>{{ title }}</span>
                <slot name="header"></slot>
                <helpIcon
                    v-if="helpIcon"
                    style="margin-left: 6px"
                    :content="content"
                    :isWhiteSpace="isWhiteSpace"
                >
                </helpIcon>
            </div>
            <slot></slot>
        </el-card>
    </div>
</template>

<script>
import helpIcon from '@/components/helpIcon'
export default {
    name: 'card',
    props:{
        title:String,
        content:String,
        helpIcon:Boolean,
        isWhiteSpace:Boolean,
        hidHead:Boolean,
    },
    data: () => ({

    }),
    components: {
        helpIcon,
    },
}
</script>

<style scoped lang="scss">
.box-card {
    box-shadow:none;
    border: none;
    &.hidHead {
        ::v-deep .el-card__header {
            display: none;
        }
    }
    .title {
        font-size: 16px;
        font-weight: bold;
        color: rgba(51, 51, 51, 1);
    }
    ::v-deep .el-card__header {
        margin:0 20px;
        padding: 18px 0px;
    }
    ::v-deep .el-card__body {
        padding:40px 20px;
    }
    
}
</style>