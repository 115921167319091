<template>
    <div>
        <el-card class="box-card" :class="`${isIdCard?'IdCard':''}`">
            <div slot="header" class="title flex-center justify-center">
                <span class="font-14 fw-400">{{ title }}</span>
            </div>
            <div v-if="isLicense">
                <el-form ref="form" label-position="right" :rules="licRule" :model="licInfo" class="ml-30">
                    <el-form-item :label="text" prop="merchant_name" label-width="150px">
                        <div class="columnBox">
                            <el-input class="inputBtn" v-model="licInfo.merchant_name" placeholder="请输入内容"></el-input>
                            <p class="content">需与{{text2}}上的{{ text }}完全一致</p>
                        </div>
                    </el-form-item>
                    <el-form-item label="统一社会信用代码" prop="credit_code" label-width="150px">
                        <div class="columnBox">
                            <el-input class="inputBtn" v-model="licInfo.credit_code" placeholder="请输入内容" :disabled="isEditCtf"></el-input>
                            <p class="content">请输入15位登记证书号或18位的统一社会信用代码</p>
                        </div>
                    </el-form-item>
                    <el-form-item label="营业期限" prop="date" label-width="150px">
                        <div class="columnBox expirationDate" :class="[licInfo.business_term==2&&'block']">
                            <el-radio-group v-model="licInfo.business_term">
                                <el-radio :label="1">非长期有效</el-radio>
                                <el-radio :label="2">长期有效</el-radio>
                            </el-radio-group>
                            <el-date-picker v-model="licInfo.date" type="daterange" range-separator="至" start-placeholder="开始日期"
                                end-placeholder="结束日期" align="right" unlink-panels value-format="timestamp"
                                :picker-options="pickerOptions" v-if="licInfo.business_term==1"></el-date-picker>
                            <p class="content">请选择{{text2}}上的营业期限，结束时间需大于开始时间有效期必须大于60天</p>
                        </div>
                    </el-form-item>
                    <el-form-item label="注册地址" prop="registered_address" label-width="150px">
                        <el-input class="inputBtn" type="textarea" placeholder="请填写详细地址，例12号楼8层801室" v-model="licInfo.registered_address"
                            maxlength="200" show-word-limit :autosize="{ minRows: 4, maxRows: 6 }">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="经营范围" prop="business_scope" label-width="150px">
                        <el-input class="inputBtn" type="textarea" placeholder="请输入内容" v-model="licInfo.business_scope"
                            maxlength="1000" show-word-limit :autosize="{ minRows: 4, maxRows: 6 }">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="经营者姓名" prop="operator_name" label-width="150px">
                        <div class="columnBox">
                            <el-input class="inputBtn" v-model="licInfo.operator_name" placeholder="请输入内容"></el-input>
                            <p class="content">{{ operatorText }}</p>
                        </div>
                    </el-form-item>
                </el-form>
            </div>
            <div v-if="isIdCard">
                <el-form ref="form" label-position="right" :rules="cardRule" :model="cardInfo" class="ml-30">
                    <el-form-item label="证件持有人姓名" prop="id_name" label-width="150px">
                        <div class="columnBox">
                            <el-input class="inputBtn" v-model="cardInfo.id_name" placeholder="请输入内容"></el-input>
                        </div>
                    </el-form-item>
                    <el-form-item label="证件号码" prop="id_number" label-width="150px">
                        <div class="columnBox">
                            <el-input class="inputBtn" v-model="cardInfo.id_number" placeholder="请输入内容"></el-input>
                        </div>
                    </el-form-item>
                    <el-form-item label="证件有效期" prop="date" label-width="150px">
                        <div class="columnBox expirationDate" :class="[cardInfo.id_long_term==2&&'block']">
                            <el-radio-group v-model="cardInfo.id_long_term">
                                <el-radio :label="1">非长期有效</el-radio>
                                <el-radio :label="2">长期有效</el-radio>
                            </el-radio-group>
                            <el-date-picker v-model="cardInfo.date" type="daterange" range-separator="至" start-placeholder="开始日期"
                                end-placeholder="结束日期" align="right" unlink-panels value-format="timestamp"
                                :picker-options="pickerOptions" v-if="cardInfo.id_long_term==1"></el-date-picker>
                        </div>
                    </el-form-item>
                    <el-form-item label="证件地址" prop="id_address" label-width="150px">
                        <el-input class="inputBtn" type="textarea" placeholder="请填写详细地址，例12号楼8层801室" v-model="cardInfo.id_address"
                            maxlength="80" show-word-limit :autosize="{ minRows: 4, maxRows: 6 }">
                        </el-input>
                    </el-form-item>
                </el-form>
            </div>
        </el-card>
    </div>
</template>

<script>
export default {
    name: 'cardLicense',
    props:{
        title:String,
        isLicense:Boolean,
        isIdCard:Boolean,
        info:Object,
        type:String,
        certStatus:Number,
    },
    computed:{
      isEditCtf() {
          return this.type=='edit-business'&&this.certStatus!=3
      },
      text() {
        let str = ''
        switch (this.info.entity_type) {
          case 2:
            str = '商户名称'
            break;
          case 3:
            str = '企业名称'
            break;
          case 4:
            str = '机构名称'
            break;
          case 5:
            str = '组织名称'
            break;
        }
        return str
      },
      text2() {
        let str = ''
        switch (this.info.entity_type) {
          case 2:
          case 3:
            str = '营业执照'
            break;
          case 4:
            str = '统一社会信用代码证书'
            break;
          case 5:
            str = '登记证书'
            break;
        }
        return str
      },
      operatorText() {
        let str = ''
        switch (this.info.entity_type) {
          case 2:
          case 3:
            str = '请输入营业执照上的经营者姓名'
            break;
          case 4:
            str = '请输入统一社会信用代码证书上的姓名'
            break;
          case 5:
            str = '请输入登记证书上的姓名'
            break;
        }
        return str
      },
    },
    watch:{
        info:{
            handler(newObj){
                // 编辑需要赋值
                if(this.type != 'edit-person'&&this.type != 'edit-business') return
                if(this.isLicense) {
                    for (const key in this.licInfo) {
                        const filter = ['business_term','date']
                        if(!filter.includes(key)) this.licInfo[key] = newObj[key]||''
                    }
                    this.licInfo = Object.assign(this.licInfo,{
                        business_term: Number(newObj.business_term),
                        date: Number(newObj.business_term)==1?[newObj.business_start_time*1000,newObj.business_end_time*1000]:[], // 如果info中没有date，则使用空数组作为默认值
                    })
                }
                if(this.isIdCard) {
                    for (const key in this.cardInfo) {
                        const filter = ['id_long_term','date']
                        if(!filter.includes(key)) this.cardInfo[key] = newObj[key]||''
                    }
                    this.cardInfo = Object.assign(this.cardInfo,{
                        id_long_term: Number(newObj.id_long_term),
                        date: Number(newObj.id_long_term)==1?[newObj.id_start_time*1000,newObj.id_end_time*1000]:[], // 如果info中没有date，则使用空数组作为默认值
                    })
                }
            },
            immediate:true,
        }
    },
    data(){
        const validateDateRange = (rule, value, callback) => {
            const isLongTerm = this.licInfo.business_term
            const date = this.licInfo.date
            if(Number(isLongTerm)==2){
                callback();
            } else if (date && date.length === 2) {
                const startDate = new Date(date[0] * 1000); // 将时间戳转换为Date对象  
                const endDate = new Date(date[1] * 1000);
                if (startDate > endDate) {
                    callback(new Error('开始日期不能晚于结束日期'));
                } else {
                    // 这里可以添加其他业务逻辑验证  
                    callback();
                }
            } else {
                callback(new Error('请选择有效的日期范围'));
            }
        };
        const validateCreditCode = (rule, value, callback) => {
            if (!value) {
                callback(new Error('请输入统一社会信用代码'));
            } 
            // else if (
            //     !(value.length==15||value.length==18)
            // ) {
            //     callback(new Error('输入的统一社会信用代码位数不对'));
            // } 
            else  {
                callback();
            }
        };
        // const validateIdCard = (rule, value, callback) => {
        //     const regex = /^(\d{15}$)|^\d{17}([0-9]|X)$/;
        //     if (regex.test(value)) {
        //         callback();
        //     } else {
        //         callback(new Error('请输入有效的身份证号码'));
        //     }
        // };
        // isIdCard
        const validateDateRange2 = (rule, value, callback) => {
            const isLongTerm = this.cardInfo.id_long_term
            const date = this.cardInfo.date
            if(Number(isLongTerm)==2){
                callback();
            } else if (date && date.length === 2) {
                const startDate = new Date(date[0] * 1000); // 将时间戳转换为Date对象  
                const endDate = new Date(date[1] * 1000);
                if (startDate > endDate) {
                    callback(new Error('开始日期不能晚于结束日期'));
                } else {
                    // 这里可以添加其他业务逻辑验证  
                    callback();
                }
            } else {
                callback(new Error('请选择有效的日期范围'));
            }
        };
        return {
            licInfo:{
                merchant_name:'',
                credit_code:'',
                business_term:1,
                date: [],
                registered_address: '',
                business_scope: '',
                operator_name: '',
            },
            licRule:{
                merchant_name: [
                    { required: true, message: '请输入商户名称', trigger: 'blur' },
                ],
                credit_code: [
                    // 自定义校验规则
                    {
                        required: true,
                        validator: validateCreditCode,
                        trigger: ['blur', 'change'],
                    },
                ],
                date: [
                    { required: true,validator: validateDateRange, trigger: 'change' },
                ],
                registered_address: [
                    { required: true, message: '请填写详细地址', trigger: 'blur' },
                ],
                business_scope: [
                    { required: true, message: '请填写经营范围', trigger: 'blur' },
                ],
                operator_name: [
                    { required: true, message: '请填写经营者姓名', trigger: 'blur' },
                ],
            },
            cardInfo:{
                id_name:'',
                id_number:'',
                id_long_term: 1,
                date: [],
                id_address: '',
            },
            cardRule:{
                id_name: [
                    { required: true, message: '请输入证件姓名', trigger: 'blur' },
                ],
                id_number: [
                    { required: true, message: '请输入身份证号码', trigger: 'blur' },
                    // { validator: validateIdCard, trigger: 'blur' }
                ],
                date: [
                    { required: true,validator: validateDateRange2, trigger: 'change' },
                ],
                id_address: [
                    { required: true, message: '请填写详细地址', trigger: 'blur' },
                ],
            },
            pickerOptions: {},
        }
    },
    components: {
    },
    methods:{
        submit() {
            return new Promise((res, rej) => {
                this.$refs.form.validate((valid) => {
                    console.log(valid, this.licInfo);
                    if (valid) {
                        if(this.isLicense)  return res(this.licInfo)
                        if(this.isIdCard) return res(this.cardInfo)
                        res()
                    } else {
                        rej(false)
                    }
                })
            })
        },
    }
}
</script>

<style scoped lang="scss">
.box-card {
    box-shadow:none;
    border: none;

    .title {
        font-size: 16px;
        font-weight: bold;
        color: rgba(51, 51, 51, 1);
    }
    ::v-deep .el-card__header {
        margin:0px;
        padding: 0px;
        background: rgb(235,243,255);
    }
    ::v-deep .el-card__body {
        padding:40px 20px;
    }
    .inputBtn {
        max-width: 500px;
    }
    .content {
        font-size: 14px;
        color: #898989;
        line-height: 19px;
        text-align: left;
        font-style: normal;
    }
    &.IdCard {
        ::v-deep .el-card__body {
            padding: 40px 20px 0 20px;
        }
    }
    .expirationDate {
        padding-top: 12px;
        &.block {
            display: flex;
        }
    }
}
::v-deep .el-card__body {
    padding:40px 0 !important;
}
::v-deep .el-form-item {
    margin-bottom: 22px;
    .el-form-item__label {
        // text-align: right;
    }
}
</style>